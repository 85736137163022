import React, { useEffect } from 'react';
import { object, string } from 'prop-types';
import lozad from 'lozad';

import styles from './Calendly.module.scss';
import { RichText } from 'prismic-reactjs';

const Calendly = ({ primary }) => {
  const { title, link } = primary;

  // const titleText =
  //   title.richText.length === 1
  //     ? title.richText[0].text.split('\n')
  //     : [title.richText[0].text, title.richText[1].text];

  useEffect(() => {
    const observer = lozad();
    observer.observe();
  }, []);

  // console.log('primary', primary);
  // console.log('calendlyLink', calendlyLink);

  return (
    <section className={styles.calendly}>
      <div className={styles.title}>
        {/* <h2>
          <strong>{titleText[0]}</strong>
          <br />
          {titleText[1]}
        </h2> */}
        <RichText render={title.richText} />
      </div>
      <div
        className={styles.calendar}
        role="region"
        aria-label="Calendly Booking Calendar"
      >
        <iframe
          src={
            link?.url
            // ? `${calendlyLink}?hide_gdpr_banner=1`
            // : 'https://calendly.com/secure-privacy/45min?hide_gdpr_banner=1'
          }
          sp-consent="Calendly"
          title="Calendly"
          width="100%"
          height="100%"
          frameBorder="0"
          className="lozad"
        ></iframe>
      </div>
    </section>
  );
};

Calendly.propTypes = {
  primary: object,
  calendlyLink: string,
};

export default Calendly;
